<template>
    <div>       
        <s-crud
            title="Expectativas Razonables"        
            add
            edit            
            remove
            @save="save($event)"
            :config="configReasonableExp"
            :filter="filter"
            
        >   
            
            <template v-slot:filter>
            <v-col>
                <v-row justify="center">
                <v-col lg="3" >
                    <s-select-definition clearable v-model="filter.TypeProductivityMeasurement" :def="1281" label="Punto de Medición de Productividad"> 
                    </s-select-definition>
                </v-col>
                </v-row>
            </v-col>
            </template>
            <template slot-scope="props">
                <v-container fluid>
                    <v-row>
                        <v-col>                            
                            <s-textarea
                                label="Descripción de la Expectativa"
                                v-model="props.item.RexDescription"
                                autofocus
                                clearable                             
                                :rules="rules"                                
                            ></s-textarea>
                        </v-col>                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form"  sm="5" md="5" lg="5">
                             <s-select-definition :def="1281" label="Medición de Productividad" v-model="props.item.TypeProductivityMeasurement" clearable add></s-select-definition>
                        </v-col>
                        <v-col cols="12" class="s-col-form"  sm="2" md="2" lg="2">
                            <s-text label="Unidades/Hora" v-model="props.item.RexMeasurement" decimal></s-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form"  sm="3" md="3" lg="3">
                            <s-select-definition :def="1275" label="Unidad de Medida" v-model="props.item.TypeUnitOfMeasurement" clearable add></s-select-definition>
                        </v-col>
                        <v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
                            <s-select-definition v-model="props.item.RexStatus" :def="1276" label="Estado"></s-select-definition>
                        </v-col>
                    </v-row>                    
                </v-container>
                <v-container>
                    <!-- <v-row>
                        <v-col cols="12" class="s-col-form"  sm="12" md="12" lg="12">
                            <s-textarea label="Descripción de la Expectativa" v-model="props.item.RexDescription" autofocus  clearable clear-icon="mdi-close-circle"></s-textarea>                           
                        </v-col>
                    </v-row> -->
                                        
                </v-container>
            </template>
            <template v-slot:RexStatus="{ row }">
                <v-chip style="margin:0px" x-small :color="row.RexStatus == 1 ? 'success' : 'error'">
                    {{ row.RexStatus == 1 ? "Activo" : "Inactivo" }}
                </v-chip>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
                    {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
                </v-chip>
            </template>
            
        </s-crud>
    </div>
</template>
<script>
import _sPrfReasonableExpectationService from "@/services/FreshProduction/PrfReasonableExpectationService"
import STextarea from '../../../components/Utils/sTextarea.vue'
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue'

export default {
    components: {STextarea, SSelectDefinition},
     
    data(){
        return{
             
            filter: {TypeProductivityMeasurement:0}, 
            rules: [v => v.length <= 300 || 'Máx. 300 caracteres'],
            configReasonableExp: {
                model: {
                    RexID: "ID",
                    RexDescription: "string",
                    RexMeasurement: "int",
                    TypeProductivityMeasurementText: "string",
                    TypeUnitOfMeasurementText: "string",
                    RexStatus: "status",
                    SecStatus: "status",
                },
                service: _sPrfReasonableExpectationService,
                headers: [
                    { text: "ID", value: "RexID"  },
                    { text: "Descripción", value: "RexDescription", width: 450 },
                    { text: "Medición de Productividad", value: "TypeProductivityMeasurementText", align: "center"},
                    { text: "Medición", value: "RexMeasurement", align:"center"},
                    { text: "Unidad de Medida", value: "TypeUnitOfMeasurementText", align: "center" },
                    { text: "Estado", value: "RexStatus", width: 70},
                ]
            },
        }
    },
    methods: {
        save(item) {
            if(item.RexDescription.length == 0) {
                this.$fun.alert("Digite una descripción", "warning")
                
                return;
            }
            if(item.RexDescription.length >= 301){
                this.$fun.alert("Ha superado la cantidad de caracteres permitido en la descripción", "info")
                return;
            }
            if(item.TypeProductivityMeasurement == '' || item.TypeProductivityMeasurement == null){
                this.$fun.alert("Seleccione una medición de Productividad", "warning")
                return;
            }
            if(item.RexMeasurement == '' || item.RexMeasurement == null){
                this.$fun.alert("Ingrese la medición", "warning")
                return;
            }
            if(item.TypeUnitOfMeasurement == '' || item.TypeUnitOfMeasurement ==null){
                this.$fun.alert("Seleccione una unidad de medida", "warning")
                return;
            }
            item.SecStatus=1;
            item.save();
        }
    },
    
    created () {
        this.filter = {};
    },    
}
</script>


