import Service from "../Service";
import Vue from "vue";
const resource = "createReasonableExpectation/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
          params: { requestID : requestID },
        });
      },

    save(rex, requestID) {
        return Service.post(resource + 'save', rex, {
            params: { requestID : requestID }
        });
    },
/* 
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID }
        });
    },

    getrowcolumncooling(requestID) {
        return Service.post(resource + "getrowcolumncooling", {}, {
            params: { requestID }
        });
    } */
}